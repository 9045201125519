import styled from 'styled-components';
import { gap } from '../../../styles/settings/spacing';
import { smBodyRegular } from '../Typography/index.styled';

export const StyledLink = styled(smBodyRegular)`
	color: inherit;
	text-decoration: none;
	transition: background-color, color 125ms ease-in;
	margin-bottom: ${gap[0]};
	padding-bottom: ${gap[4]};
	width: fit-content;
	cursor: pointer;
`;
