import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { Subsite , FooterTypes } from '@/types';

import { pushEvent } from '@/lib/tracking';
import BruntwoodSciTechLogoSVG from '../../../svgs/BruntwoodSciTechLogo';
import Twitter from '../../../svgs/Social/Twitter';
import LinkedIn from '../../../svgs/Social/LinkedIn';
import Instagram from '../../../svgs/Social/Instagram';
import Youtube from '../../../svgs/Social/Youtube';
import Link from '../Link';
import LogoLink from '../LogoLink';


import Styled from './index.styled';

const FooterSciTech = (props) => {

	const theme = useContext(ThemeContext);
	const { address, links }: FooterTypes = props;
	const currentYear = new Date().getFullYear();

	return (
		<Styled.Footer as="footer" groupTheme={Subsite.BruntwoodSciTech}>
			<Styled.FooterInner fullWidth>
				<Styled.FooterContact fullWidth>

				<Styled.FooterTitle as="h3">Registered Address</Styled.FooterTitle>
					<Styled.FooterAddress as="address">{address}</Styled.FooterAddress>
				<Styled.FooterHr />

					<Styled.FooterUl>
						{links.map((link, index) => (
							<Styled.FooterLi key={`${link.title}--${index}`}>
								<Link
									url={link.url}
									title={link.title}
									dataGACategory={link.dataGACategory}
									dataGAAction={link.dataGAAction}
								/>
							</Styled.FooterLi>
						))}
					</Styled.FooterUl>

					</Styled.FooterContact>

				<Styled.FooterBottom fullWidth>
					<Styled.FooterSocialUl>
						<Styled.FooterSocialLi>
							<a
								href="https://twitter.com/BW_SciTech"
								aria-label="Follow us on Twitter"
								target="_blank"
								data-ga-category="component - footer"
								data-ga-action="Twitter"
								onClick={pushEvent}
								rel="noreferrer"
							>
								<Twitter />
							</a>
						</Styled.FooterSocialLi>
						<Styled.FooterSocialLi>
							<a
								href="https://linkedin.com/company/bruntwood-scitech "
								aria-label="Find us on LinkedIn"
								target="_blank"
								data-ga-category="component - footer"
								data-ga-action="LinkedIn"
								onClick={pushEvent}
								rel="noreferrer"
							>
								<LinkedIn />
							</a>
						</Styled.FooterSocialLi>
						<Styled.FooterSocialLi>
							<a
								href="https://instagram.com/BruntwoodSciTech"
								aria-label="Follow us on Instagram"
								target="_blank"
								data-ga-category="component - footer"
								data-ga-action="Instagram"
								onClick={pushEvent}
								rel="noreferrer"
							>
								<Instagram />
							</a>
						</Styled.FooterSocialLi>
						<Styled.FooterSocialLi>
							<a
								href="https://www.youtube.com/channel/UC_BYuDLQvvJVKpQGf9aHrUA"
								aria-label="Find us on Youtube"
								target="_blank"
								data-ga-category="component - footer"
								data-ga-action="Youtube"
								onClick={pushEvent}
								rel="noreferrer"
							>
								<Youtube />
							</a>
						</Styled.FooterSocialLi>
					</Styled.FooterSocialUl>

					<LogoLink
						url="/"
						dataGACategory="component - footer"
						dataGAAction="Bruntwood logo"
						dataGALabel="Bruntwood logo"
						footer
					>
						<BruntwoodSciTechLogoSVG fill={theme?.white} />
					</LogoLink>

					<Styled.FooterCopyright>
						<Styled.Copyright>
							Copyright &copy; {currentYear} Bruntwood SciTech
						</Styled.Copyright>
						<Styled.Copyright>
							Bruntwood SciTech, a company registered in England and Wales,
							registration number 03814666
						</Styled.Copyright>
					</Styled.FooterCopyright>
				</Styled.FooterBottom>
			</Styled.FooterInner>
		</Styled.Footer>
	);
};

export default FooterSciTech;
