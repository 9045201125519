import React from 'react';

import { FooterTypes, Subsite } from '../../../types';

import { useSubSite } from '../../providers/SubSite';
import FooterMain from './FooterMain';
import FooterSciTech from './FooterSciTech';
import FooterWorks from './FooterWorks';

type FooterProps = {
	footers: FooterTypes[];
};

const Footer = ({ footers }: FooterProps) => {
	const currentSubSite = useSubSite();

	if (currentSubSite === Subsite.BruntwoodSciTech) {
		const footer = footers.find(x => x.subsite === Subsite.BruntwoodSciTech);
		return footer ? <FooterSciTech {...footer} /> : null;
	}
	if (currentSubSite === Subsite.BruntwoodWorks) {
		const footer = footers.find(x => x.subsite === Subsite.BruntwoodWorks);
		return footer ? <FooterWorks {...footer} /> : null;
	}

	const footer = footers.find(x => x.subsite === Subsite.BruntwoodGroup);
	return footer ? <FooterMain {...footer} /> : null;
};

export default Footer;
