import React from 'react';
import { StyledLink } from './index.styled';
import Browser from '../../../lib/browser';

type LinkProps = {
	url: string;
	dataGACategory?: string;
	dataGAAction?: string;
	dataGALabel?: string;
	target?: string;
	title: string;
};

const Link = ({ url, dataGACategory, dataGAAction, dataGALabel, target, title }: LinkProps) => (
	<StyledLink
		as="a"
		href={url}
		data-ga-action={dataGAAction}
		data-ga-category={dataGACategory}
		data-ga-label={dataGALabel}
		target={target}
		onClick={event => Browser.handleClick(event, url)}
		rel="noopener noreferrer"
	>
		{title}
	</StyledLink>
);

export default Link;
